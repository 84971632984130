.layout_mainContainer {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.layout_headerDiv {
  display: flex;
  justify-content: right;
}

.layout_followDiv {
  display: flex;
  flex-direction: row;
}

.layout_followText {
  display: flex;
  width: 80px;
  align-items: center;
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 22px;
}

.layout_followIconDiv {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.layout_followIconImg {
  height: 32px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
