.navigationBar_container {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.navigationBar_titleContainer {
  display: flex;
  flex-direction: row;
  background-color: #454545;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navigationBar_titleNameDiv {
  display: flex;
  width: 65%;
  justify-content: left;
  align-items: center;
}

.navigationBar_titleNameImg {
  height: 40px;
}

.navigationBar_titleLogoDiv {
  display: flex;
  width: 35%;
  justify-content: right;
  align-items: center;
}

.navigationBar_titleLogoImg {
  height: 40px;
}

.navigationBar_hamburgerButtonDiv {
  display: none; /* Not needed outside of media query. */
  width: 100%;
  height: 50px;
  align-items: center;
  background-color: #ffe8a4;
  border: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
}

.navigationBar_hamburgerButton {
  display: none; /* Not needed outside of media query. */
  width: 32px;
  height: 32px;
  margin-left: 20px;
  position: relative;
  z-index: 3;
}

.navigationBar_hamburgerButtonImg {
  display: none; /* Not needed outside of media query. */
  width: 32px;
}

.navigationBar_ul1 {
  display: flex;
  width: 100%;
  background-color: #ffe8a4;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;
  margin: 0px;
}

.navigationBar_ul1_show {
  display: flex;
  width: 100%;
  background-color: #ffe8a4;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;
  margin: 0px;
}

.navigationBar_li1 {
  list-style: none;
  z-index: 4;
}

.navigationBar_ul2 {
  display: none;
  width: 200px;
  background-color: #ffe8a4;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 0px;
  margin: 0px;
  margin-top: 2px;
  position: absolute;
  z-index: 3;
}

.navigationBar_ul2_show {
  display: block;
  width: 200px;
  background-color: #ffe8a4;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 0px;
  margin: 0px;
  margin-top: 2px;
  position: absolute;
  z-index: 3;
}

.navigationBar_li2 {
  list-style: none;
}

.navigationBar_link {
  text-decoration: none;
}

.navigationBar_div1 {
  color: #454545;
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 20px;
  height: 50px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.navigationBar_div1:hover {
  background-color: #ffc107;
}

.navigationBar_collapseChevronDiv {
  width: 15px;
  margin-right: 6px;
}

.navigationBar_collapseChevronImgCollapsed {
  width: 100%;
  height: auto;
  transition: 0.1s;
}

.navigationBar_collapseChevronImgExpanded {
  width: 100%;
  height: auto;
  transition: 0.1s;
  transform: rotate(90deg);
}

.navigationBar_div2 {
  color: #454545;
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigationBar_div2:hover {
  background-color: #ffc107;
}

.navigationBar_dropdownBackground {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 2;
}

/* Media query styles for tablet/mobile screens. */
@media all and (max-width: 768px) {
  .navigationBar_hamburgerButtonDiv {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    background-color: #ffe8a4;
    border: 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  }

  .navigationBar_hamburgerButton {
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    position: relative;
    z-index: 3;
  }

  .navigationBar_hamburgerButtonImg {
    display: block;
    width: 32px;
  }

  .navigationBar_ul1 {
    display: none;
    width: 300px;
    background-color: #ffe8a4;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
  }

  .navigationBar_ul1_show {
    display: block;
    width: 300px;
    background-color: #ffe8a4;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
    position: absolute;
    z-index: 3;
  }

  .navigationBar_li1 {
    list-style: none;
    /* border-bottom: solid 1px black; */
  }

  .navigationBar_ul2_show {
    width: 100%;
    background-color: #ffe8a4;
    padding: 0px;
    margin: 0px;
    display: block;
    position: relative;
    box-shadow: 0 0 0 0;
    z-index: 3;
  }

  .navigationBar_div1 {
    color: black;
    font-family: "BarlowRegular", "Tahoma", sans-serif;
    font-size: 18px;
    height: 50px;
    padding-left: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    z-index: 3;
  }

  .navigationBar_div2 {
    color: black;
    font-family: "BarlowRegular", "Tahoma", sans-serif;
    font-size: 16px;
    height: 50px;
    padding-left: 54px;
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 4;
  }
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
