.privacyPolicyPage_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 20px;
}

.privacyPolicyPage_underline {
  height: 1px;
  width: 50%;
  background-image: linear-gradient(to right, #0f63a4, #b0d2ec);
  margin-bottom: 10px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
