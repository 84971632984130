.questionResults_responseCountsDiv {
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionResults_responseCounts {
  display: inline-block;
  margin-right: 50px;
  font-family: "BarlowRegular";
  font-size: 22px;
}

.questionResults_responseCounts > strong {
  font-family: "BarlowBold";
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
}

.questionResults_userResponse {
  font-family: "BarlowRegular";
  font-size: 24px;
  margin-bottom: 20px;
}

.questionResults_userResponse > strong {
  font-family: "BarlowBoldItalic";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
}

.questionResults_screenshotButton {
  height: 30px;
  margin-bottom: 30px;
  background-color: #0f63a4;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 16px;
  border: 0;
}

.questionResults_screenshotButton:hover {
  height: 30px;
  margin-bottom: 30px;
  background-color: #548fbd;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 16px;
  border: 0;
}

.questionResults_collapseHeadingDiv {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
}

.questionResults_collapseHeadingDiv:hover {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: rgb(220, 220, 220);
}

.questionResults_collapseChevronDiv {
  width: 15px;
  margin-right: 10px;
}

.questionResults_collapseChevronImgCollapsed {
  width: 100%;
  height: auto;
  transition: 0.15s;
}

.questionResults_collapseChevronImgExpanded {
  width: 100%;
  height: auto;
  transition: 0.15s;
  transform: rotate(90deg);
}

.questionResults_collapseHeading {
  font-family: "ShareRegular";
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  margin: 0px;
}

.questionResults_filtersContainer {
  border-top: solid black 1px;
}

.questionResults_byDimensionContainer {
  border-top: solid black 1px;
}

.questionResults_applyFilters {
  height: 30px;
  width: 180px;
  background-color: #0f63a4;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 16px;
  border: 0;
}

.questionResults_applyFilters:hover {
  height: 30px;
  width: 180px;
  background-color: #548fbd;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 16px;
  border: 0;
}

.questionResults_divCollapsed {
  display: none;
  height: 0%;
  transition: 2s;
}

.questionResults_divExpanded {
  display: block;
  height: 100%;
  padding-bottom: 30px;
  transition: 2s;
}

.questionResults_randomQuestionButtonContainer {
  display: flex;
  justify-content: center;
  border-top: solid black 1px;
  padding-top: 30px;
  margin-bottom: 10px;
  height: 40px;
}

.questionResults_link {
  text-decoration: none;
}

.questionResults_randomQuestionButtonDiv {
  height: 40px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f63a4;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.questionResults_randomQuestionButtonDiv:hover {
  height: 40px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #548fbd;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.questionResults_bottomBufferContainer {
  margin-bottom: 60px;
}

/* Screenshot styles */
.questionResults_screenshot_containerOuter {
  position: relative;
  overflow: hidden;
}

.questionResults_screenshot_containerInner {
  width: 500px;
  position: absolute;
  right: -5000px;
  padding-top: 4px;
  padding-bottom: 24px;
  padding-left: 30px;
  padding-right: 30px;
}

.questionResults_screenshot_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionResults_screenshot_underline {
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #0f63a4, #b0d2ec);
  margin-bottom: 20px;
}

.questionResults_screenshot_footerContainer {
  display: flex;
  width: 200px;
  margin-top: 10px;
}

.questionResults_screenshot_nameLogoImg {
  height: 50px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
