.newUserForm_infoPrompt {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.newUserForm_selectContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.newUserForm_selectDiv {
  padding-left: 22px;
  padding-right: 22px;
}

.newUserForm_selectLabel {
  font-family: "BarlowRegular", sans-serif;
  font-size: 18px;
}

.newUserForm_select_sex {
  height: 36px;
  width: 80px;
  background-color: #FFE8A4;
  color: black;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  margin-left: 3px;
  padding-left: 5px;
}

.newUserForm_select_decade {
  height: 36px;
  width: 90px;
  background-color: #FFE8A4;
  color: black;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  margin-left: 3px;
  padding-left: 5px;
}

.newUserForm_select_location {
  height: 36px;
  width: 160px;
  background-color: #FFE8A4;
  color: black;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  margin-left: 3px;
  padding-left: 5px;
}

.newUserForm_checkboxDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newUserForm_checkbox {
  height: 18px;
  width: 18px;
  background-color: rgb(213, 213, 213);
  border-style: solid;
  border-color: black;
  border-width: 2px;
  border-radius: 1px;
  margin: 5px;
  margin-right: 10px;
}

.newUserForm_checkboxLabel {
  font-family: "BarlowRegular", sans-serif;
  font-size: 18px;
}

.newUserForm_checkboxLabel > strong {
  font-family: "BarlowBold", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}

.newUswerForm_infoPromptLink {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  color: #0F63A4;
  text-decoration: none;
}

.newUserForm_submitDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 40px;
}

.newUserForm_submit {
  height: 40px;
  width: 90px;
  background-color: #0F63A4;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.newUserForm_submit:hover {
  height: 40px;
  width: 90px;
  background-color: #548FBD;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.newUserForm_turnstileContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 30px;
}

.newUserForm_turnstileNotificationContainer {
  display: flex;
  justify-content: center;
}

.newUserForm_turnstileNotification {
  width: 70%;
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.newUswerForm_turnstileLink {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  color: #0F63A4;
  text-decoration: none;
}

/* Media query styles for tablet/mobile screens. */
@media all and (max-width: 768px) {
  .newUserForm_selectContainer {
    display: block;
  }

  .newUserForm_selectDiv {
    display: flex;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .newUserForm_selectLabel {
    display: inline-block;
    width: 160px;
    font-family: "BarlowRegular", sans-serif;
    font-size: 18px;
  }

  .newUserForm_select_sex {
    height: 36px;
    width: 160px;
    background-color: #FFE8A4;
    color: black;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    margin-left: 3px;
    padding-left: 5px;
  }

  .newUserForm_select_decade {
    height: 36px;
    width: 160px;
    background-color: #FFE8A4;
    color: black;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    margin-left: 3px;
    padding-left: 5px;
  }

  .newUserForm_select_location {
    height: 36px;
    width: 160px;
    background-color: #FFE8A4;
    color: black;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    margin-left: 3px;
    padding-left: 5px;
  }
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
