.answerQuestionNewUserPage_titleContainer {
  display: flex;
  flex-direction: row;
  background-color: #454545;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
}

.answerQuestionNewUserPage_titleNameDiv {
  display: flex;
  width: 65%;
  justify-content: left;
  align-items: center;
}

.answerQuestionNewUserPage_titleNameImg {
  height: 40px;
}

.answerQuestionNewUserPage_titleLogoDiv {
  display: flex;
  width: 35%;
  justify-content: right;
  align-items: center;
}

.answerQuestionNewUserPage_titleLogoImg {
  height: 40px;
}

.answerQuestionNewUserPage_mainContainer {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.answerQuestionNewUserPage_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 20px;
}

.answerQuestionNewUserPage_underline {
  height: 1px;
  width: 50%;
  background-image: linear-gradient(to right, #0f63a4, #b0d2ec);
  margin-bottom: 20px;
}

.answerQuestionNewUserPage_ul {
  margin: 0;
  padding: 0;
}

.answerQuestionNewUserPage_submitDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 40px;
}

.answerQuestionNewUserPage_submit {
  height: 40px;
  width: 90px;
  background-color: #0f63a4;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.answerQuestionNewUserPage_submit:hover {
  height: 40px;
  width: 90px;
  background-color: #548fbd;
  color: white;
  font-family: "BarlowRegular", "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.answerQuestionNewUserPage_turnstileContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 30px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
